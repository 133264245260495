<template>
  <div id="user_form" v-if="productCategoryData">
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="mb-0 ml-0 color4 font-weight-bolder">
        {{ productCategoryData.id == 0 ? "Add" : "Edit" }} Product Category
      </h3>
    </div>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <!-- Form: Personal Info Form -->
        <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="mt-2 mt-sm-3">
            <!-- Field: Parent Category -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Parent Category"
                rules=""
              >
                <b-form-group
                  label="Parent Category"
                  label-for="parent_id"
                  label-cols-sm="3"
                  label-cols="12"
                >
                  <div class="form-col-select">
                    <v-select
                      v-model="productCategoryData.parent_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="productCategoryOptions"
                      label="name"
                      :reduce="(option) => option.id"
                      :clearable="true"
                      placeholder="Select Parent Category"
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Field: Parent Category Display-->
            <!-- <b-col
              cols="12"
              md="6"
              v-if="productCategoryData.parent_id != null"
            >
              <p>gi</p>
            </b-col> -->
            <hr class="w-100" />

            <!-- Field: Name -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Name"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label="Name"
                  label-for="name"
                  label-cols-sm="3"
                  label-cols="12"
                >
                  <b-form-input
                    id="name"
                    v-model="productCategoryData.name"
                    :state="getValidationState(validationContext)"
                    placeholder="Enter Product Category name"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <b-form-group label-for="has_image">
                <b-form-checkbox
                  :checked="productCategoryData.has_image ? true : false"
                  @change="
                    productCategoryData.has_image =
                      !productCategoryData.has_image
                  "
                  class="custom-control-success"
                  name="check-button"
                  switch
                  inline
                />
                {{ productCategoryData.has_image ? "Has Image" : "Has Image" }}
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Field: Product Category Banner Image -->
          <b-row
            class="m-0 w-100"
            v-if="productCategoryData.has_image === true"
          >
            <b-col
              cols="12"
              class="d-flex align-item-center justify-content-center"
            >
              <el-upload
                class="avatar-uploader"
                list-type="picture-card"
                ref="upload_image"
                :action="uploadUrl"
                :headers="headerObj"
                name="image_path"
                multiple
                :limit="1"
                :data="productCategoryData"
                :on-change="onChange"
                :on-remove="onChange"
                :auto-upload="false"
              >
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </b-col>

            <b-col
              cols="12"
              md="3"
              class="mt-2"
              key="product_has_image"
              v-if="imageChanged === false && productCategoryData.id > 0 && productCategoryData.image_path !== null"
            >
              <el-image
                style="width: 100%; aspect-ratio: 2 /1; padding: 0"
                fit="cover"
                :src="`${uploadUrl}${productCategoryData.image_path}`"
                :preview-src-list="documentList"
              >
              </el-image>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group label-for="has_children">
                <b-form-checkbox
                  :checked="productCategoryData.has_children ? true : false"
                  @change="
                    productCategoryData.has_children =
                      !productCategoryData.has_children
                  "
                  class="custom-control-success"
                  name="check-button"
                  switch
                  inline
                />
                {{
                  productCategoryData.has_children
                    ? "Has Children"
                    : "Has Children"
                }}
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label-for="is_active">
                <b-form-checkbox
                  :checked="productCategoryData.is_active ? true : false"
                  @change="
                    productCategoryData.is_active =
                      !productCategoryData.is_active
                  "
                  class="custom-control-success"
                  name="check-button"
                  switch
                  inline
                />
                {{ productCategoryData.is_active ? "Is Active" : "Is Active" }}
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Button: Submit & Reset Button.-->

          <hr />
          <div class="d-flex mt-1 flex-wrap flex-sm-nowrap justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-sm-2 mobile-w100"
              type="submit"
            >
              <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="mt-1 mt-sm-0 mobile-w100"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { ref, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { heightTransition } from "@core/mixins/ui/transition";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";

export default {
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  components: {
    BRow,
    BCol,
    BForm,
    BOverlay,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  methods: {
    onChange(file, fileLists) {
      this.imageChanged = true;
      this.productCategoryData.image_path = file.raw;
    },
    hide() {
      this.$router.replace("/product/categories");
    },
    onSubmit() {
      console.log("submit data", this.productCategoryData);
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save Changes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;

          let formData = new FormData();

          if (this.productCategoryData.parent_id !== null) {
            formData.append("parent_id", this.productCategoryData.parent_id);
          }
          formData.append("name", this.productCategoryData.name);
          formData.append("image_changed", this.imageChanged ? 1 : 0);
          formData.append(
            "has_image",
            this.productCategoryData.has_image ? 1 : 0
          );
          formData.append(
            "has_children",
            this.productCategoryData.has_children ? 1 : 0
          );
          formData.append(
            "is_active",
            this.productCategoryData.is_active ? 1 : 0
          );
          formData.append("id", this.productCategoryData.id);
          formData.append("image_path", this.productCategoryData.image_path);
          store
            .dispatch("app-product-categories/updateProductCategory", formData)
            .then((response) => {
              this.loading = false;

              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$router.replace("/product/categories");
            })
            .catch((error) => {
              this.loading = false;

              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  initTrHeight() {
    this.trSetHeight(null);
    this.$nextTick(() => {
      this.trSetHeight(this.$refs.form.scrollHeight);
    });
  },
  props: {
    productCategoryData: {
      type: Object,
      required: true,
    },
    productCategoryOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    console.log(props.productCategoryData);
    const uploadUrl = ref(process.env.VUE_APP_IMAGE_URL);
    const documentList = ref([]);
    documentList.value = [
      `${uploadUrl.value}${props.productCategoryData.image_path}`,
    ];

    const headerObj = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };

    const loading = ref(false);
    const imageChanged = ref(false);

    const resetData = () => {
      emit("refetch-data");
    };
    const resetproductCategoryData = () => {
      props.clientData = JSON.parse(JSON.stringify({}));
    };
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetproductCategoryData
    );

    onMounted(() => {});

    return {
      loading,
      imageChanged,
      resetData,
      refFormObserver,
      getValidationState,
      resetForm,
      resetproductCategoryData,
      documentList,
      uploadUrl,
      headerObj,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.teamleader_selected {
  background: #110f6a;
  padding: 3px 10px;
  border-radius: 3px;
  color: white;
}

.addParent {
  position: relative;
}

.addChildren {
  position: absolute;
  right: -5px;
  bottom: 35%;
  padding: 2px !important;
  background: #a03071;
  border-radius: 15px;
  color: white;
}

.removeChildren {
  position: absolute;
  right: -5px;
  bottom: 35%;
  padding: 2px !important;
  background: #d14f4f;
  border-radius: 15px;
  color: white;
}
</style>
