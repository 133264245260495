import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProductCategoriesDisplay(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/product_categories/listdisplay`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchProductCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/product_categories/list`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchProductCategory(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/product_categories`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addProductCategory(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/product_categories", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateProductCategory(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/product_categories", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
