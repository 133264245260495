<template>
  <component :is="productCategoryData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="productCategoryData === undefined">
      <h4 class="alert-heading">Error fetching product category data</h4>
      <div class="alert-body">
        No product category found with this product category id. Check
        <b-link class="alert-link" :to="{ name: 'product-category-list' }">
          Product Category List
        </b-link>
        for other product categorys.
      </div>
    </b-alert>

    <b-tabs v-if="productCategoryData" class="tabs-primary" pills>
      <!-- Tab: Information -->
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Product Category</span>
        </template>
        <product-categories-tab-information
          :product-category-data="productCategoryData"
          :product-category-options="productCategoryOptions"
          @refetch-data="refetchData"
          class="mt-2 pt-75"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import productCategoryStoreModule from "../productCategoryStoreModule";
import ProductCategoriesTabInformation from "./ProductCategoriesTabInformation.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    ProductCategoriesTabInformation,
  },
  setup() {
    const productCategoryData = ref(null);
    const productCategoryOptions = ref([]);
    const PRODUCT_CATEGORY_APP_STORE_MODULE_NAME = "app-product-categories";

    const product_category = {
      id: 0,
      name: null,
      parent_id: null,
      has_image: 0,
      image_path: null,
      has_children: 1,
      is_active: 1,
    };

    // Register module
    if (!store.hasModule(PRODUCT_CATEGORY_APP_STORE_MODULE_NAME))
      store.registerModule(
        PRODUCT_CATEGORY_APP_STORE_MODULE_NAME,
        productCategoryStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_CATEGORY_APP_STORE_MODULE_NAME))
        store.unregisterModule(PRODUCT_CATEGORY_APP_STORE_MODULE_NAME);
    });

    const refetchOption = () => {
      store
        .dispatch("app/fetchOptionList", {
          product_categories: true,
        })
        .then((response) => {
          productCategoryOptions.value = response.data.product_categories;
        });
    };

    const refetchData = () => {
      if (router.currentRoute.name == "product-category-create") {
        productCategoryData.value = product_category;
      } else {
        store
          .dispatch("app-product-categories/fetchProductCategory", {
            id: router.currentRoute.params.id,
          })
          .then((response) => {
            console.log("get product categpry response", response);
            productCategoryData.value = response.data.product_category;
          })
          .catch((error) => {
            console.log("error when fetching product categpry", error);
            if (error.response.status === 404) {
              productCategoryData.value = undefined;
            }
          });
      }
    };

    onMounted(() => {
      refetchOption();
    }),
      refetchData();

    return {
      productCategoryData,
      refetchData,
      refetchOption,
      productCategoryOptions,
    };
  },
};
</script>

<style></style>
